var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeOperatorName
    ? _c(
        "div",
        {
          staticClass: "MainView animated fadeIn d-flex flex-column",
          attrs: { "data-test-id": "top-navigation" },
        },
        [
          _c(
            "ui-navbar",
            {
              staticClass: "px-0 mr-3 z-index-120",
              attrs: {
                height: _vm.navbarHeight,
                fixed: true,
                "data-test-id": "header",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "MainView__mobileTogglerMenu color-ink cursor-pointer d-flex d-md-none pt-2",
                  attrs: { "data-test-id": "logo_mobile-container" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMenu.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("ui-icon", {
                    attrs: {
                      icon: _vm.ICONS.menuOptions,
                      size: _vm.ICONS_SIZES.huge,
                      "data-test-id": "logo_menu-icon",
                    },
                  }),
                ],
                1
              ),
              _c(
                "RouterLink",
                {
                  attrs: {
                    to: {
                      name: _vm.dashboard.index,
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "MainView__logo ml-5 d-none d-md-block mt-1",
                    style: {
                      "max-height": _vm.navbarHeight,
                      height: "40px",
                    },
                    attrs: {
                      src: require("../../assets/logo_mobility.png"),
                      "data-test-id": "logo-image",
                      alt: "EMobG logo",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "MainView__searchResults" },
                [
                  _vm.activeOperator && !_vm.activeOperator.is_carrental
                    ? _c("OmnisearchComponent", {
                        attrs: {
                          config: _vm.omnisearchConfig(),
                          "active-operator-id": _vm.activeOperatorId,
                          "active-operator-uuid": _vm.activeOperatorUuid,
                          "is-active-operator-child": _vm.isActiveOperatorChild,
                          "operator-children": _vm.operatorChildren,
                          "href-only": false,
                          "data-test-id": "omnisearch-component",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.userAccount.user.STATUS.LOADED ||
                        _vm.userAccount.user.STATUS.ERROR,
                      expression:
                        "userAccount.user.STATUS.LOADED || userAccount.user.STATUS.ERROR",
                    },
                  ],
                  staticClass: "animated fadeIn",
                  attrs: { "data-test-id": "user_area-container" },
                },
                [
                  _c(
                    "ui-dropdown",
                    {
                      staticClass: "MainView__operators-dropdown",
                      attrs: {
                        color: _vm.GRAYSCALE.white,
                        "data-test-id": "operators-dropdown",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "trigger" }, slot: "trigger" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pr-3 emobg-color-ink",
                              attrs: {
                                "data-test-id": "user_details-container",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between mt-1",
                                },
                                [
                                  _c("div", { staticClass: "mr-2 mt-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "align-right emobg-body-2",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pr-1 d-inline-block",
                                            attrs: {
                                              "data-test-id":
                                                "first_name-label",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.userAccount.user.data
                                                    .first_name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-id": "last_name-label",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.userAccount.user.data
                                                  .last_name
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "emobg-caption-1 emobg-font-weight-light ellipsis",
                                        attrs: {
                                          "data-test-id": "email-label",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.userAccount.user.data.email
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("ui-avatar", {
                                    attrs: {
                                      label: _vm.activeOperatorInitials,
                                      "data-test-id":
                                        "active_operator_name-label",
                                      "label-test-id":
                                        "selected-country-operator",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "MainView__operators-dropdown-items",
                            },
                            _vm._l(_vm.operatorsComposed, function (operator) {
                              return _c(
                                "div",
                                { key: operator.id, staticClass: "d-block" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center cursor-pointer emobg-background-color-ground-light-hover p-2",
                                      class: [
                                        `${
                                          operator.uuid ===
                                          _vm.activeOperatorUuid
                                            ? "active"
                                            : ""
                                        }`,
                                      ],
                                      on: {
                                        click: () =>
                                          _vm.updateActiveOperator(operator),
                                      },
                                    },
                                    [
                                      _c("ui-avatar", {
                                        key: operator.id,
                                        attrs: {
                                          label: operator.initials,
                                          size: _vm.SIZES.small,
                                          "label-test-id":
                                            "selected-country-operator",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-block mw-100 px-2 emobg-body-2",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(operator.name) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "MainView__userMenu" }, [
                            !_vm.isActiveOperatorChild
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "MainView__userMenu__item emobg-border-top-4 emobg-border-color-ground-lighter",
                                  },
                                  [
                                    _c(
                                      "PermissionLink",
                                      {
                                        staticClass: "d-block p-3",
                                        attrs: {
                                          "link-permissions": [
                                            _vm.CRM_PERMISSIONS.viewCrmUsers,
                                          ],
                                          to: {
                                            name: _vm.crm.users.detail.index,
                                            params: {
                                              userUuid:
                                                _vm.userAccount.user.data.uuid,
                                            },
                                          },
                                          "data-test-id": "edit_profile-link",
                                          "class-link":
                                            "cursor-pointer emobg-color-black emobg-body-2",
                                        },
                                      },
                                      [_vm._v(" Edit profile ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "MainView__userMenu__item emobg-border-top-4 emobg-border-color-ground-lighter",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "cursor-pointer d-block p-3 emobg-color-black emobg-body-2",
                                    attrs: {
                                      href: _vm.HELP_CENTRE_LINK,
                                      "data-test-id": "help_centre-link",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(" Help Centre ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "MainView__userMenu__item p-3 cursor-pointer emobg-border-top-4 emobg-border-color-ground-lighter emobg-body-2",
                                attrs: { "data-test-id": "logout-link" },
                                on: { click: _vm.logoutAuth0 },
                              },
                              [_vm._v(" Log Out ")]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userAccount.user.STATUS.LOADED,
                      expression: "userAccount.user.STATUS.LOADED",
                    },
                  ],
                  staticClass:
                    "beamer emobg-border-left-1 emobg-border-color-ground pl-2 pt-1",
                  attrs: { id: "beamer", "data-test-id": "beamer-icon" },
                },
                [
                  _c("ui-icon", {
                    attrs: {
                      icon: _vm.ICONS.alarm,
                      size: _vm.ICONS_SIZES.huge,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("NotificationList"),
          _c(
            "ui-sidebar",
            {
              attrs: {
                open: _vm.isSidebarVisible,
                color: _vm.COLORS.secondary,
                width: _vm.sideBarWidth,
                "data-test-id": "left_navigation-panel",
              },
              on: {
                mouseenter: _vm.expandSidebar,
                mouseleave: _vm.collapseSidebar,
              },
            },
            [
              _c(
                "div",
                [
                  _c("MenuItemsListComponent", {
                    attrs: { "data-test-id": "left_navigation-menu" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "MainView__container py-5 d-flex flex-column",
              style: {
                "margin-top": _vm.navbarHeight,
                "margin-left": `${_vm.containerMargin}px`,
              },
            },
            [
              _vm.$route.name !== _vm.dashboard.index
                ? _c(
                    "div",
                    { staticClass: "container-fluid d-none d-md-block mb-5" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 px-5" },
                          [
                            _c("BreadcrumbsComponent", {
                              attrs: {
                                "breadcrumb-config": _vm.breadcrumbConfig,
                                "data-test-id": "breadcrumbs-component",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("RouterView"),
              _c("GlobalMessagingComponent", {
                attrs: { "data-test-id": "message-component" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.isEmpty(_vm.userError)
    ? _c("ui-loader", {
        staticClass: "center",
        attrs: { label: "Loading data...", "data-test-id": "loader-component" },
      })
    : _c("RouterView")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }