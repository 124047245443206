export const BREADCRUMB_PATH_CONFIG = [
  {
    urlRegexp: /.+\/vehicles/g,
    label: 'Vehicles',
    children: [
      {
        urlRegexp: /.+\/vehicles\/[A-Z0-9]+\/summary\/[a-z]+/g,
        label: 'Vehicle detail',
        children: [
          {
            urlRegexp: /.+\/vehicles\/[A-Z0-9]+\/summary\/[a-z]+\/trip-summary/g,
            label: 'Trip detail',
          },
        ],
      },
    ],
  },
  {
    urlRegexp: /.+\/alerts/g,
    label: 'Alerts',
    children: [
      {
        urlRegexp: /.+\/alerts\/[A-Z0-9]+\/[a-z0-9-]+\/details/g,
        label: 'Alert detail',
      },
    ],
  },
  {
    urlRegexp: /.+\/movements/g,
    label: 'Movements',
  },
  {
    urlRegexp: /.+\/fleet-check-list/g,
    label: 'Automated fleet check',
    children: [
      {
        urlRegexp: /.+\/fleet-check-list\/new-fleet-check/g,
        label: 'New fleet check',
      },
    ],
  },
  {
    urlRegexp: /.+\/stations/g,
    label: 'Stations',
    children: [
      {
        urlRegexp: /.+\/stations\/station\/[a-z0-9-]+/g,
        label: 'Station detail',
      },
    ],
  },
  {
    urlRegexp: /.+\/fleet-management/g,
    label: 'Fleet management',
  },
  {
    urlRegexp: /.+\/locations-map/g,
    label: 'Locations map',
  },
  {
    urlRegexp: /.+\/configurable-alert\/list/g,
    label: 'Alert triggers',
    children: [
      {
        urlRegexp: /.+\/configurable-alert\/list\/add-trigger/g,
        label: 'New alert trigger',
      },
      {
        urlRegexp: /.+\/configurable-alert\/list\/[a-z0-9-]+\/details/g,
        label: 'Alert trigger detail',
      },
    ],
  },
  {
    urlRegexp: /.+\/devices/g,
    label: 'Devices',
  },
  {
    urlRegexp: /.+\/agents/g,
    label: 'Agents',
  },
  {
    urlRegexp: /.+\/summary-report/g,
    label: 'Summary report',
  },
];
