import BookingAlerts from '../components/BookingAlerts';
import NotConfirmedBookings from '../components/NotConfirmedBookings';
import OpenInvoicesWithFinishedBookings from '../components/OpenedInvoicesWithFinishedBookings';
import InvoicesWithoutNif from '../components/InvoicesWithoutNif';
import FinalOpenInvoices from '../components/FinalOpenInvoices';
import NonCorrelativeInvoices from '../components/NonCorrelativeInvoices';
import SignUps from '../components/SignUps';
import InvoicesVsSap from '../components/InvoicesVsSap';
import InvoicesWithChargebacks from '../components/InvoicesWithChargebacks';
import BookingInvoicesKmsMismatch from '../components/BookingInvoicesKmsMismatch/BookingInvoicesKmsMismatch';

export const WIDGET_COMPONENTS = {
  1: SignUps,
  2: BookingAlerts,
  3: NotConfirmedBookings,
  4: NonCorrelativeInvoices,
  5: FinalOpenInvoices,
  6: InvoicesWithoutNif,
  8: OpenInvoicesWithFinishedBookings,
  9: BookingInvoicesKmsMismatch,
  10: InvoicesWithChargebacks,
  11: InvoicesVsSap,
};
