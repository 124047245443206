<script>
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { PermissionLink } from '@/components';

export default {
  name: 'InvoicesListTemplate',
  components: {
    PermissionLink,
  },
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.INVOICING_PERMISSIONS = INVOICING_PERMISSIONS;
  },
};
</script>
<template>
  <div class="InvoicesListTemplate">
    <PermissionLink
      v-for="invoice in invoices"
      :key="invoice.ref"
      :href="`/invoicing/invoices/${invoice.id}/summary/details`"
      :link-permissions="[INVOICING_PERMISSIONS.viewInvoicingInvoices]"
      :text="invoice.ref"
      target="_blank"
      class="emobg-font-weight-semibold d-block mb-1"
    />
  </div>
</template>
