<script>
import get from 'lodash/get';
import { DASHBOARD_SCOPES } from '@domains/Dashboard/store/DashboardModule';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TableComponent } from '@/components';
import { WIDGET_SCHEMAS } from '../const/widgets-config.const';
import { alertOcurrencesText } from '../const/widgets.utils';

export default {
  name: 'InvoicesVsSap',
  components: {
    TableComponent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.dashboard.dashboard, {
      sapExport: state => state[DASHBOARD_SCOPES.sapExport].data || [],
      sapExportStatus: state => state[DASHBOARD_SCOPES.sapExport].STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: userState => get(userState, 'operators.active.id'),
    }),
    alertOcurrences() {
      return alertOcurrencesText(get(this, 'sapExport.ocurrences'));
    },
  },
  watch: {
    activeOperatorId() {
      this.getSapExportVsInvoices(this.activeOperatorId);
    },
  },
  async created() {
    this.WIDGET_SCHEMAS = WIDGET_SCHEMAS;
    await this.getSapExportVsInvoices(this.activeOperatorId);
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.dashboard.dashboard, ['getSapExportVsInvoices']),
  },
};
</script>

<template>
  <div
    class="InvoicesVsSap"
    data-test-id="invoices_vs_sap-widget"
  >
    <div class="position-relative">
      <ui-loader
        v-if="sapExportStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <h5 class="emobg-label-3 emobg-font-weight-semibold mb-2">
        {{ title }}
      </h5>
      <TableComponent
        :schema="WIDGET_SCHEMAS.sapExport.comparisonByMonths"
        :data="get(sapExport, 'alerts.comparisonByMonths', [])"
        data-test-id="comparison-table"
      />

      <h5 class="emobg-label-3 emobg-font-weight-semibold mt-4 mb-2">
        Invoices missing in SAP ({{ alertOcurrences }})
      </h5>
      <TableComponent
        :schema="WIDGET_SCHEMAS.sapExport.missingInvoices"
        :data="get(sapExport, 'alerts.missingInvoices', [])"
        data-test-id="missing_invoices-table"
      />
    </div>
  </div>
</template>
