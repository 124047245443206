var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "InvoicesVsSap",
      attrs: { "data-test-id": "invoices_vs_sap-widget" },
    },
    [
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.sapExportStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "h5",
            { staticClass: "emobg-label-3 emobg-font-weight-semibold mb-2" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("TableComponent", {
            attrs: {
              schema: _vm.WIDGET_SCHEMAS.sapExport.comparisonByMonths,
              data: _vm.get(_vm.sapExport, "alerts.comparisonByMonths", []),
              "data-test-id": "comparison-table",
            },
          }),
          _c(
            "h5",
            {
              staticClass: "emobg-label-3 emobg-font-weight-semibold mt-4 mb-2",
            },
            [
              _vm._v(
                " Invoices missing in SAP (" +
                  _vm._s(_vm.alertOcurrences) +
                  ") "
              ),
            ]
          ),
          _c("TableComponent", {
            attrs: {
              schema: _vm.WIDGET_SCHEMAS.sapExport.missingInvoices,
              data: _vm.get(_vm.sapExport, "alerts.missingInvoices", []),
              "data-test-id": "missing_invoices-table",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }