<script>
import get from 'lodash/get';
import { DASHBOARD_SCOPES } from '@domains/Dashboard/store/DashboardModule';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'SignUps',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.dashboard.dashboard, {
      signUps: state => state[DASHBOARD_SCOPES.signUps].data || [],
      signUpsStatus: state => state[DASHBOARD_SCOPES.signUps].STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: userState => get(userState, 'operators.active.id'),
    }),
  },
  watch: {
    activeOperatorId() {
      this.getSignUps(this.activeOperatorId);
    },
  },
  async created() {
    await this.getSignUps(this.activeOperatorId);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.dashboard.dashboard, ['getSignUps']),
  },
};
</script>

<template>
  <div
    class="SignUps"
    data-test-id="signups-widget"
  >
    <h5 class="emobg-label-3 emobg-font-weight-semibold mb-2">
      {{ title }}
    </h5>
    <div class="position-relative">
      <ui-loader
        v-if="signUpsStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="emobg-background-color-white p-2">
        <h3 class="emobg-font-weight-semibold">
          {{ signUps.signups }}
        </h3>
      </div>
    </div>
  </div>
</template>
