<script>
import get from 'lodash/get';
import size from 'lodash/size';
import first from 'lodash/first';
import each from 'lodash/each';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import last from 'lodash/last';
import concat from 'lodash/concat';
import words from 'lodash/words';
import nth from 'lodash/nth';
import find from 'lodash/find';

import { mapActions, mapGetters, mapState } from 'vuex';
import Cookie from 'js-cookie';

import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  HTTP_STATUS_CODES, LOG_TYPE,
  logger, navigationErrorHandler,
} from '@emobg/web-utils';
import { authService } from '@emobg/access-utils';
import dashboard from '@domains/Dashboard/router/DashboardRouterMap';
import { BREADCRUMB_PATH_CONFIG } from '@domains/Carrental/const/breadcrumbConfig.const';

import { BreadcrumbsComponent, NotificationList, PermissionLink } from '@/components';
import { logoutAuth0 } from '@/utils';
import { MSBO_COOKIE_NAMES } from '@/constants/cookies.const';

import { omnisearchConfig } from './config/omnisearch';
import { documentTitleGenerator, setDocumentTitle } from './titles.utils';

const OmnisearchComponent = () => import(/* webpackChunkName: "omnisearch" */'@/components/Omnisearch/OmnisearchComponent');
const MenuItemsListComponent = () => import(/* webpackChunkName: "menu" */'@/components/MenuItemsList/MenuItemsListComponent');
const GlobalMessagingComponent = () => import(/* webpackChunkName: "messages" */'@/components/GlobalMessaging/GlobalMessagingComponent');

const pJson = require('../../../package.json');

const BOversion = pJson.version;
const motionVersion = get(pJson, 'dependencies.@emobg/motion-ui');

const headerSize = 80;
const sidebarCollapsedWidth = 44;
const sidebarExpandededWidth = 242;
const HELP_CENTRE_LINK = 'https://europcar.service-now.com/sp';

export default {
  name: 'MainView',
  components: {
    GlobalMessagingComponent,
    MenuItemsListComponent,
    BreadcrumbsComponent,
    NotificationList,
    OmnisearchComponent,
    PermissionLink,
  },
  data() {
    return {
      containerMargin: sidebarCollapsedWidth,
      sideBarWidth: sidebarCollapsedWidth,
      isSidebarVisible: true,
      breadcrumbConfig: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userAccount: state => state,
      operators: state => get(state, 'operators.data'),
      userError: state => get(state, 'user.error'),
      activeOperatorName: state => get(state, 'operators.active.name'),
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      activeOperatorId: state => get(state, 'operators.active.id'),
      activeUserUuid: state => get(state, 'user.data.uuid'),
      operatorChildren: state => get(state, 'operators.active.childrenIds'),
      isCarRental: state => get(state, 'operators.active.is_carrental'),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter', 'isActiveOperatorChild', 'activeOperator']),
    documentTitle() {
      return documentTitleGenerator(this.$route, this.$store);
    },
    operatorsComposed() {
      return map(this.operators, operator => {
        const operatorSplitted = words(operator.name);
        const secondInitial = size(operatorSplitted) === 1 ? nth(operatorSplitted, 1) : last(operatorSplitted);
        return {
          ...operator,
          initials: concat(first(operatorSplitted), secondInitial),
        };
      });
    },
    activeOperatorInitials() {
      return get(find(this.operatorsComposed, ['name', this.activeOperatorName]), 'initials');
    },
  },
  watch: {
    documentTitle: {
      handler() {
        setDocumentTitle(this.documentTitle);
      },
      immediate: true,
    },
    activeOperator: {
      handler(operator) {
        authService.instance.activeOperatorId = get(operator, 'id');
        this.breadcrumbConfig = this.isCarRental ? BREADCRUMB_PATH_CONFIG : [];
      },
      immediate: true,
    },
  },
  created() {
    this.navbarHeight = `${headerSize}px`;
    this.omnisearchConfig = omnisearchConfig.bind(this);
    this.HELP_CENTRE_LINK = HELP_CENTRE_LINK;
    this.dashboard = dashboard;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.crm = crm;
  },
  async mounted() {
    const version = document.createElement('meta');
    version.setAttribute('name', 'version');
    version.setAttribute('backoffice', BOversion);
    version.setAttribute('motion-ui', motionVersion);
    const head = document.querySelector('head');
    head.insertBefore(version, head.firstChild);
    try {
      if (!size(this.operators) || !this.activeUserUuid) {
        await this.getUserAndOperators();
      }
      if (!this.activeUserUuid) { throw new Error('No user'); }
      if (!size(this.operators)) { throw new Error('No operators'); }
      await this.setOperatorsWithChildren();
      try {
        const activeOperator = JSON.parse(Cookie.get(MSBO_COOKIE_NAMES.operator));
        if (activeOperator) {
          this.updateActiveOperator(activeOperator);
        }
      } catch (error) {
        logger.message('Default operator is set as the first of available operators');
        if (!size(this.operators)) { throw new Error('No operators found for current user'); }
        this.updateActiveOperator(first(this.operators));
      }
    } catch (error) {
      this.setErrorMessage(get(this.userError, 'message'));
      this.$router.push(`/${get(this.userError, 'status') || HTTP_STATUS_CODES.internalServerError}`).catch(navigationErrorHandler);
      logger.message(error, LOG_TYPE.error);
    }

    if (window.matchMedia) {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      mediaQuery.addEventListener('change', this.onScreenWidthChange);
      this.onScreenWidthChange(mediaQuery);
    }
  },
  methods: {
    isEmpty,
    logoutAuth0,
    ...mapActions(DOMAINS_MODEL.app.userAccount, [
      'getUserAndOperators',
      'setActiveOperator',
      'setOperatorChildren',
    ]),
    ...mapActions(DOMAINS_MODEL.app.menuItems, [
      'setSidebarExpanded',
      'setMenuItemOpen',
      'getMenuItems',
    ]),
    ...mapActions(DOMAINS_MODEL.app.feedbackDialog, [
      'setErrorMessage',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.cities, [
      'getCities',
    ]),
    async updateActiveOperator(operator) {
      await this.setActiveOperator(operator);
      this.getMenuItems({
        userUuid: this.activeUserUuid,
        operatorUuid: this.activeOperatorUuid,
      });
      this.getCities({
        csOperatorUuid: this.activeOperatorUuid,
      });
      /**
       * reset activeOperator datalayer
       * https://www.bounteous.com/insights/2016/03/21/removing-values-datalayer-data-layer-best-practices-pt-4
       */
      window.dataLayer.push({
        activeOperator: undefined,
      });
      /**
       * Insert into dataLayer the activeOperator
       */
      window.dataLayer.push({
        activeOperator: this.activeOperator,
      });
    },

    onScreenWidthChange({ matches }) {
      if (matches && this.isSidebarVisible) {
        this.isSidebarVisible = false;
        this.containerMargin = 0;
      } else {
        this.isSidebarVisible = true;
        this.containerMargin = sidebarCollapsedWidth;
      }
    },

    toggleMenu() {
      this.isSidebarVisible = !this.isSidebarVisible;
      this.containerMargin = 0;
    },

    collapseSidebar() {
      this.sideBarWidth = sidebarCollapsedWidth;
      this.setMenuItemOpen();
      this.setSidebarExpanded(false);
    },
    expandSidebar() {
      this.sideBarWidth = sidebarExpandededWidth;
      this.setSidebarExpanded(true);
    },
    async setOperatorsWithChildren() {
      const { hits: allOperators } = await this.$algolia.fetchIndex(
        'cs_operators',
        {
          hitsPerPage: 1000,
        },
      );
      const operatorChildrenById = new Map();
      each(this.operators, operator => {
        const operatorChildren = filter(allOperators, {
          parent_cs_operator_id: operator.id,
        });
        operatorChildrenById.set(operator.id, operatorChildren);
      });
      this.setOperatorChildren(operatorChildrenById);
    },
  },
};
</script>

<template>
  <div
    v-if="activeOperatorName"
    class="MainView animated fadeIn d-flex flex-column"
    data-test-id="top-navigation"
  >
    <ui-navbar
      :height="navbarHeight"
      :fixed="true"
      class="px-0 mr-3 z-index-120"
      data-test-id="header"
    >
      <span
        class="MainView__mobileTogglerMenu color-ink cursor-pointer d-flex d-md-none pt-2"
        data-test-id="logo_mobile-container"
        @click.prevent="toggleMenu"
      >
        <ui-icon
          :icon="ICONS.menuOptions"
          :size="ICONS_SIZES.huge"
          data-test-id="logo_menu-icon"
        />
      </span>
      <RouterLink
        :to="{
          name: dashboard.index,
        }"
      >
        <img
          :style="{
            'max-height': navbarHeight,
            height: '40px',
          }"
          src="../../assets/logo_mobility.png"
          data-test-id="logo-image"
          class="MainView__logo ml-5 d-none d-md-block mt-1"
          alt="EMobG logo"
        >
      </RouterLink>

      <div class="MainView__searchResults">
        <OmnisearchComponent
          v-if="activeOperator && !activeOperator.is_carrental"
          :config="omnisearchConfig()"
          :active-operator-id="activeOperatorId"
          :active-operator-uuid="activeOperatorUuid"
          :is-active-operator-child="isActiveOperatorChild"
          :operator-children="operatorChildren"
          :href-only="false"
          data-test-id="omnisearch-component"
        />
      </div>

      <div
        v-show="userAccount.user.STATUS.LOADED || userAccount.user.STATUS.ERROR"
        class="animated fadeIn"
        data-test-id="user_area-container"
      >
        <ui-dropdown
          :color="GRAYSCALE.white"
          class="MainView__operators-dropdown"
          data-test-id="operators-dropdown"
        >
          <div slot="trigger">
            <div
              class="pr-3 emobg-color-ink"
              data-test-id="user_details-container"
            >
              <div class="d-flex justify-content-between mt-1">
                <div class="mr-2 mt-1">
                  <div class="align-right emobg-body-2">
                    <span
                      class="pr-1 d-inline-block"
                      data-test-id="first_name-label"
                    >
                      {{ userAccount.user.data.first_name }}
                    </span>
                    <span data-test-id="last_name-label">{{ userAccount.user.data.last_name }}</span>
                  </div>
                  <div
                    class="emobg-caption-1 emobg-font-weight-light ellipsis"
                    data-test-id="email-label"
                  >
                    {{ userAccount.user.data.email }}
                  </div>
                </div>
                <ui-avatar
                  :label="activeOperatorInitials"
                  data-test-id="active_operator_name-label"
                  label-test-id="selected-country-operator"
                />
              </div>
            </div>
          </div>
          <div slot="content">
            <div class="MainView__operators-dropdown-items">
              <div
                v-for="operator in operatorsComposed"
                :key="operator.id"
                class="d-block"
              >
                <div
                  :class="[`${operator.uuid === activeOperatorUuid ? 'active' : ''}`]"
                  class="d-flex align-items-center cursor-pointer emobg-background-color-ground-light-hover p-2"
                  @click="() => updateActiveOperator(operator)"
                >
                  <ui-avatar
                    :key="operator.id"
                    :label="operator.initials"
                    :size="SIZES.small"
                    label-test-id="selected-country-operator"
                  />
                  <div class="d-block mw-100 px-2 emobg-body-2">
                    {{ operator.name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="MainView__userMenu">
              <div
                v-if="!isActiveOperatorChild"
                class="MainView__userMenu__item emobg-border-top-4 emobg-border-color-ground-lighter"
              >
                <PermissionLink
                  :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
                  :to="{
                    name: crm.users.detail.index,
                    params: {
                      userUuid: userAccount.user.data.uuid,
                    },
                  }"
                  data-test-id="edit_profile-link"
                  class="d-block p-3"
                  class-link="cursor-pointer emobg-color-black emobg-body-2"
                >
                  Edit profile
                </PermissionLink>
              </div>
              <div class="MainView__userMenu__item emobg-border-top-4 emobg-border-color-ground-lighter">
                <a
                  :href="HELP_CENTRE_LINK"
                  data-test-id="help_centre-link"
                  class="cursor-pointer d-block p-3 emobg-color-black emobg-body-2"
                  target="_blank"
                >
                  Help Centre
                </a>
              </div>
              <div
                class="MainView__userMenu__item p-3 cursor-pointer emobg-border-top-4 emobg-border-color-ground-lighter emobg-body-2"
                data-test-id="logout-link"
                @click="logoutAuth0"
              >
                Log Out
              </div>
            </div>
          </div>
        </ui-dropdown>
      </div>
      <div
        v-show="userAccount.user.STATUS.LOADED"
        id="beamer"
        data-test-id="beamer-icon"
        class="beamer emobg-border-left-1 emobg-border-color-ground pl-2 pt-1"
      >
        <ui-icon
          :icon="ICONS.alarm"
          :size="ICONS_SIZES.huge"
        />
      </div>
    </ui-navbar>
    <NotificationList />
    <ui-sidebar
      :open="isSidebarVisible"
      :color="COLORS.secondary"
      :width="sideBarWidth"
      data-test-id="left_navigation-panel"
      @mouseenter="expandSidebar"
      @mouseleave="collapseSidebar"
    >
      <!-- <div> wrapper necessary for rendering menu items -->
      <div>
        <MenuItemsListComponent data-test-id="left_navigation-menu" />
      </div>
    </ui-sidebar>
    <div
      :style="{
        'margin-top': navbarHeight,
        'margin-left': `${containerMargin}px`,
      }"
      class="MainView__container py-5 d-flex flex-column"
    >
      <div
        v-if="$route.name !== dashboard.index"
        class="container-fluid d-none d-md-block mb-5"
      >
        <div class="row">
          <div class="col-12 px-5">
            <BreadcrumbsComponent
              :breadcrumb-config="breadcrumbConfig"
              data-test-id="breadcrumbs-component"
            />
          </div>
        </div>
      </div>
      <RouterView />
      <GlobalMessagingComponent data-test-id="message-component" />
    </div>
  </div>
  <ui-loader
    v-else-if="isEmpty(userError)"
    label="Loading data..."
    class="center"
    data-test-id="loader-component"
  />
  <RouterView v-else />
</template>
