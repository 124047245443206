import { render, staticRenderFns } from "./BookingAlerts.vue?vue&type=template&id=74d0a5ab&"
import script from "./BookingAlerts.vue?vue&type=script&lang=js&"
export * from "./BookingAlerts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74d0a5ab')) {
      api.createRecord('74d0a5ab', component.options)
    } else {
      api.reload('74d0a5ab', component.options)
    }
    module.hot.accept("./BookingAlerts.vue?vue&type=template&id=74d0a5ab&", function () {
      api.rerender('74d0a5ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Dashboard/components/BookingAlerts.vue"
export default component.exports