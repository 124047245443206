import findLast from 'lodash/findLast';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { sentenceCase } from '@emobg/web-utils';

/**
 * Generates a title using store data or returns specified string title
 * @param $route
 * @param $store
 * @returns {string|*}
 */
export const documentTitleGenerator = ($route, $store) => {
  const lastRouteWithTitle = findLast(get($route, 'matched', []), 'meta.title');
  const title = get(lastRouteWithTitle, 'meta.title');
  const defaultTitle = `${get($route, 'meta.label') || sentenceCase($route.name) || ' '}`;

  if (isString(title)) {
    return title;
  }

  if (isObject(title)) {
    const { storeDomain, scope, template } = title;
    const scopePath = scope ? `.${scope}` : '';
    const path = `${storeDomain.replace(/\//g, '.')}${scopePath}.data`;
    const isLoadedPath = `${storeDomain.replace(/\//g, '.')}${scopePath}.STATUS.LOADED`;
    const isLoaded = get($store.state, isLoadedPath);

    return isLoaded ? template(get($store.state, path)) : defaultTitle;
  }

  return defaultTitle;
};

/**
 * Set the document title with the desired title
 * @param title
 */
export const setDocumentTitle = title => {
  document.title = `${title} | Mobility Backoffice`;
};
