<script>
import get from 'lodash/get';
import { DASHBOARD_SCOPES } from '@domains/Dashboard/store/DashboardModule';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TableComponent } from '@/components';
import { WIDGET_SCHEMAS } from '../const/widgets-config.const';
import { alertOcurrencesText } from '../const/widgets.utils';

export default {
  name: 'InvoicesWithoutNif',
  components: {
    TableComponent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.dashboard.dashboard, {
      invoicesWithoutNif: state => state[DASHBOARD_SCOPES.invoicesWithoutNif].data || [],
      invoicesWithoutNifStatus: state => state[DASHBOARD_SCOPES.invoicesWithoutNif].STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: userState => get(userState, 'operators.active.id'),
    }),
    alertOcurrences() {
      return alertOcurrencesText(get(this, 'invoicesWithoutNif.ocurrences'));
    },
  },
  watch: {
    activeOperatorId() {
      this.getInvoicesWithoutNif(this.activeOperatorId);
    },
  },
  async created() {
    this.WIDGET_SCHEMAS = WIDGET_SCHEMAS;
    await this.getInvoicesWithoutNif(this.activeOperatorId);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.dashboard.dashboard, ['getInvoicesWithoutNif']),
  },
};
</script>

<template>
  <div
    class="InvoicesWithoutNif"
    data-test-id="invoices_without_nif-widget"
  >
    <h5 class="emobg-label-3 emobg-font-weight-semibold mb-2">
      {{ title }} ({{ alertOcurrences }})
    </h5>
    <div class="position-relative">
      <ui-loader
        v-if="invoicesWithoutNifStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <TableComponent
        :schema="WIDGET_SCHEMAS.invoicesWithoutNif"
        :data="invoicesWithoutNif.alerts"
        data-test-id="table"
      />
    </div>
  </div>
</template>
