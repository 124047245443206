var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoicesListTemplate" },
    _vm._l(_vm.invoices, function (invoice) {
      return _c("PermissionLink", {
        key: invoice.ref,
        staticClass: "emobg-font-weight-semibold d-block mb-1",
        attrs: {
          href: `/invoicing/invoices/${invoice.id}/summary/details`,
          "link-permissions": [_vm.INVOICING_PERMISSIONS.viewInvoicingInvoices],
          text: invoice.ref,
          target: "_blank",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }