var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SignUps", attrs: { "data-test-id": "signups-widget" } },
    [
      _c(
        "h5",
        { staticClass: "emobg-label-3 emobg-font-weight-semibold mb-2" },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.signUpsStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("div", { staticClass: "emobg-background-color-white p-2" }, [
            _c("h3", { staticClass: "emobg-font-weight-semibold" }, [
              _vm._v(" " + _vm._s(_vm.signUps.signups) + " "),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }