<script>
import Cookie from 'js-cookie';
import { mapState } from 'vuex';
import each from 'lodash/each';
import { HTTP_STATUS_CODES, navigationErrorHandler } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import { SHARED_URLS } from '@/router/RouterMap';

const DIALOGS = {
  copyPasted: 'Alternatively, if you copy-pasted the URL, ensure that it was copied correctly.',
  notFound: 'Whoops,\npage not found!',
  restricted: 'Whoops,\nthis page is restricted!',
  unauthorized: 'Please ensure that you are logging with the correct account with permissions to access the Backoffice.',
  weird: 'Whoops,\nsomething weird just happened!',
};
export default {
  name: 'FeedbackDialogView',
  components: { EmptyStateComponent },
  data() {
    return {
      messageFeedback: '',
      additionalFeedback: '',
      isClearCookies: false,
      isLoginAgain: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.feedbackDialog, {
      errorMessage: state => state.errorMessage,
    }),
    ...mapState('route', {
      errorCode: state => Number(state.fullPath.slice(1)),
    }),
  },
  watch: {
    errorCode(code) {
      this.setView(code);
    },
  },
  created() {
    this.HTTP_STATUS_CODES = HTTP_STATUS_CODES;
    this.SHARED_URLS = SHARED_URLS;
  },
  mounted() {
    const code = this.errorCode;
    this.setView(code);
  },
  methods: {
    setPristineData() {
      this.messageFeedback = '';
      this.additionalFeedback = '';
      this.isClearCookies = false;
      this.isLoginAgain = false;
    },
    setView(code) {
      this.setPristineData();

      switch (code) {
        case HTTP_STATUS_CODES.unauthorized:
          this.messageFeedback = DIALOGS.weird;
          this.additionalFeedback = DIALOGS.unauthorized;
          this.isLoginAgain = true;
          this.isClearCookies = true;
          break;

        case HTTP_STATUS_CODES.forbidden:
          this.messageFeedback = DIALOGS.restricted;
          this.additionalFeedback = DIALOGS.unauthorized;
          this.isClearCookies = true;
          break;

        case HTTP_STATUS_CODES.notFound:
          this.messageFeedback = DIALOGS.notFound;
          this.additionalFeedback = DIALOGS.copyPasted;
          break;

        default:
          this.messageFeedback = DIALOGS.weird;
          this.isClearCookies = true;
          break;
      }
    },
    clearCookies() {
      window.localStorage.clear();
      const allCookies = Cookie.get();
      each(allCookies, (_value, cookie) => {
        Cookie.remove(cookie);
      });
      this.$router.push('login').catch(navigationErrorHandler);
    },
  },
};
</script>

<template>
  <div class="my-auto">
    <EmptyStateComponent
      :title="messageFeedback"
      :additional-feedback="additionalFeedback"
      :error-message="errorMessage"
      class="my-auto"
      description="Go to the dashboard or clear your browsing data in case it’s not working."
    >
      <div
        slot="buttons"
        class="mt-3"
      >
        <RouterLink
          v-if="isLoginAgain"
          :to="SHARED_URLS.login"
        >
          <ui-button
            :face="FACES.fill"
            narrow
          >
            Login again
          </ui-button>
        </RouterLink>
        <ui-button
          v-if="isClearCookies"
          :face="FACES.outline"
          narrow
          @clickbutton="clearCookies"
        >
          Clear browsing data
        </ui-button>
        <RouterLink :to="SHARED_URLS.dashboard">
          <ui-button
            :face="FACES.fill"
            class="ml-3"
            narrow
          >
            Go to dashboard
          </ui-button>
        </RouterLink>
      </div>
    </EmptyStateComponent>
  </div>
</template>
