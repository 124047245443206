<script>
import { authService } from '@emobg/access-utils';

import { allowCookiesHandler } from '../../../constants/auth';

export default {
  beforeCreate() {
    authService.instance.clearCookies(allowCookiesHandler);
    authService.instance.logout();
  },
};
</script>
<template>
  <ui-loader class="center" />
</template>
