<script>
import camelCase from 'lodash/camelCase';
import each from 'lodash/each';
import filter from 'lodash/filter';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import set from 'lodash/set';
import toNumber from 'lodash/toNumber';
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapState } from 'vuex';
import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
import { DASHBOARD_SCOPES } from '@domains/Dashboard/store/DashboardModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  EmptyStateComponent,
  PageView,
} from '@/components';
import { WIDGET_COMPONENTS } from './const/widgets.const';

export default {
  name: 'DashboardView',
  components: {
    EmptyStateComponent,
    PageView,
  },
  data() {
    return {
      grid: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      user: state => state.user.data,
    }),
    ...mapState(DOMAINS_MODEL.dashboard.dashboard, {
      widgetsData: state => state[DASHBOARD_SCOPES.userWidgets].data,
      widgetsStatus: state => state[DASHBOARD_SCOPES.userWidgets].STATUS,
      addUserWidgetStatus: state => state[DASHBOARD_SCOPES.addUserWidget].STATUS,
      deleteUserWidgetStatus: state => state[DASHBOARD_SCOPES.deleteUserWidget].STATUS,
    }),
    widgets() {
      return filter(this.widgetsData, 'userWidget');
    },
    widgetsByGroup() {
      return groupBy(this.widgetsData, 'group');
    },
    isDropdownLoading() {
      return this.addUserWidgetStatus.LOADING || this.deleteUserWidgetStatus.LOADING || this.widgetsStatus.LOADING;
    },
  },
  created() {
    this.WIDGET_COMPONENTS = WIDGET_COMPONENTS;
  },
  async mounted() {
    await this.getUserWidgets();
    this.grid = GridStack.init({ cellHeight: '70px', minRow: 1 });
    this.grid.on('resizestop', () => {
      window.dispatchEvent(new Event('resize'));
    });
    this.grid.on('change', (_event, widgets) => {
      each(widgets, widget => {
        this.putUserWidget({
          userId: this.user.id,
          widgetId: widget.id,
          data: {
            dataGsX: widget.x,
            dataGsY: widget.y,
            dataGsWidth: widget.w,
            dataGsHeight: widget.h,
          },
        });
      });
    });
  },
  methods: {
    camelCase,
    get,
    upperFirst,
    ...mapActions(DOMAINS_MODEL.dashboard.dashboard, [
      'deleteUserWidget',
      'postUserWidget',
      'putUserWidget',
      'getUserWidgets',
    ]),
    async toggleWidget(widget) {
      const isWidgetAdded = !!widget.userWidget;

      if (isWidgetAdded) {
        await this.deleteUserWidget({
          userId: this.user.id,
          widgetId: widget.id,
        });

        if (!this.deleteUserWidgetStatus.ERROR) {
          this.grid.removeWidget(`div[gs-id="${widget.id}"]`);
          set(widget, 'userWidget', null);
        }

        return;
      }

      await this.postUserWidget({
        userId: this.user.id,
        widgetId: widget.id,
      });

      if (!this.addUserWidgetStatus.ERROR) {
        set(widget, 'userWidget', {
          dataGsX: undefined,
          dataGsY: undefined,
          dataGsWidth: 4,
          dataGsHeight: 3,
        });

        this.$nextTick(async () => {
          const newWidgetSelector = `div[gs-id="${widget.id}"]`;
          this.grid.makeWidget(newWidgetSelector);

          const widgetElement = document.querySelector(newWidgetSelector);

          await this.putUserWidget({
            userId: this.user.id,
            widgetId: widget.id,
            data: {
              ...widget.userWidget,
              dataGsX: toNumber(widgetElement.getAttribute('gs-x')),
              dataGsY: toNumber(widgetElement.getAttribute('gs-y')),
            },
          });

          set(widget, 'userWidget', {
            ...widget.userWidget,
            dataGsX: widgetElement.getAttribute('gs-x'),
            dataGsY: widgetElement.getAttribute('gs-y'),
          });
        });
      }
    },
  },
};
</script>

<template>
  <PageView class="DashboardView d-flex flex-column flex-fill">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Dashboard
      </h1>
      <ui-dropdown
        :color="COLORS.primary"
        :text-color="GRAYSCALE.white"
        arrow
        class="DashboardView__widgets-dropdown"
      >
        <p
          slot="trigger"
          class="pl-3 emobg-font-weight-semibold"
        >
          Widgets
        </p>
        <div slot="content">
          <div class="d-flex flex-column text-nowrap px-1 position-relative">
            <ui-loader
              v-if="isDropdownLoading"
              absolute
              data-test-id="loader"
            />

            <template v-for="(groupWidgets, group) in widgetsByGroup">
              <p
                :key="group"
                class="emobg-label-3 emobg-font-weight-semibold p-2"
              >
                {{ upperFirst(group) }}
              </p>
              <label
                v-for="widget in groupWidgets"
                :key="`${group}-${widget.id}`"
                class="p-2 cursor-pointer"
              >
                <ui-checkbox
                  :checked="!!widget.userWidget"
                  :caption="widget.name"
                  @changevalue="toggleWidget(widget)"
                />
              </label>
            </template>
          </div>
        </div>
      </ui-dropdown>
    </div>
    <EmptyStateComponent
      v-if="!widgets.length && widgetsStatus.LOADED"
      title="You haven’t added any widgets yet"
      description="Add some widgets from the dropdown on the right"
      class="my-auto"
    />
    <div class="DashboardView__grid grid-stack mx-n2">
      <div
        v-for="widget in widgets"
        :key="widget.id"
        :gs-x="widget.userWidget.dataGsX"
        :gs-y="widget.userWidget.dataGsY"
        :gs-w="widget.userWidget.dataGsWidth || widget.defaultWidth"
        :gs-h="widget.userWidget.dataGsHeight || widget.defaultHeight"
        :gs-id="widget.id"
        class="grid-stack-item"
      >
        <div class="grid-stack-item-content">
          <div class="emobg-background-color-white emobg-border-1 emobg-border-color-ground-light p-3 full-min-height">
            <Component
              :is="get(WIDGET_COMPONENTS, widget.id)"
              v-if="get(WIDGET_COMPONENTS, widget.id)"
              :title="widget.name"
              class="flex-fill"
            />
          </div>
        </div>
      </div>
    </div>
  </PageView>
</template>
