var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "DashboardView d-flex flex-column flex-fill" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Dashboard ")]),
          _c(
            "ui-dropdown",
            {
              staticClass: "DashboardView__widgets-dropdown",
              attrs: {
                color: _vm.COLORS.primary,
                "text-color": _vm.GRAYSCALE.white,
                arrow: "",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "pl-3 emobg-font-weight-semibold",
                  attrs: { slot: "trigger" },
                  slot: "trigger",
                },
                [_vm._v(" Widgets ")]
              ),
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column text-nowrap px-1 position-relative",
                  },
                  [
                    _vm.isDropdownLoading
                      ? _c("ui-loader", {
                          attrs: { absolute: "", "data-test-id": "loader" },
                        })
                      : _vm._e(),
                    _vm._l(_vm.widgetsByGroup, function (groupWidgets, group) {
                      return [
                        _c(
                          "p",
                          {
                            key: group,
                            staticClass:
                              "emobg-label-3 emobg-font-weight-semibold p-2",
                          },
                          [_vm._v(" " + _vm._s(_vm.upperFirst(group)) + " ")]
                        ),
                        _vm._l(groupWidgets, function (widget) {
                          return _c(
                            "label",
                            {
                              key: `${group}-${widget.id}`,
                              staticClass: "p-2 cursor-pointer",
                            },
                            [
                              _c("ui-checkbox", {
                                attrs: {
                                  checked: !!widget.userWidget,
                                  caption: widget.name,
                                },
                                on: {
                                  changevalue: function ($event) {
                                    return _vm.toggleWidget(widget)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      !_vm.widgets.length && _vm.widgetsStatus.LOADED
        ? _c("EmptyStateComponent", {
            staticClass: "my-auto",
            attrs: {
              title: "You haven’t added any widgets yet",
              description: "Add some widgets from the dropdown on the right",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "DashboardView__grid grid-stack mx-n2" },
        _vm._l(_vm.widgets, function (widget) {
          return _c(
            "div",
            {
              key: widget.id,
              staticClass: "grid-stack-item",
              attrs: {
                "gs-x": widget.userWidget.dataGsX,
                "gs-y": widget.userWidget.dataGsY,
                "gs-w": widget.userWidget.dataGsWidth || widget.defaultWidth,
                "gs-h": widget.userWidget.dataGsHeight || widget.defaultHeight,
                "gs-id": widget.id,
              },
            },
            [
              _c("div", { staticClass: "grid-stack-item-content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "emobg-background-color-white emobg-border-1 emobg-border-color-ground-light p-3 full-min-height",
                  },
                  [
                    _vm.get(_vm.WIDGET_COMPONENTS, widget.id)
                      ? _c(_vm.get(_vm.WIDGET_COMPONENTS, widget.id), {
                          tag: "Component",
                          staticClass: "flex-fill",
                          attrs: { title: widget.name },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }