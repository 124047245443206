var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-auto" },
    [
      _c(
        "EmptyStateComponent",
        {
          staticClass: "my-auto",
          attrs: {
            title: _vm.messageFeedback,
            "additional-feedback": _vm.additionalFeedback,
            "error-message": _vm.errorMessage,
            description:
              "Go to the dashboard or clear your browsing data in case it’s not working.",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mt-3",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _vm.isLoginAgain
                ? _c(
                    "RouterLink",
                    { attrs: { to: _vm.SHARED_URLS.login } },
                    [
                      _c(
                        "ui-button",
                        { attrs: { face: _vm.FACES.fill, narrow: "" } },
                        [_vm._v(" Login again ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isClearCookies
                ? _c(
                    "ui-button",
                    {
                      attrs: { face: _vm.FACES.outline, narrow: "" },
                      on: { clickbutton: _vm.clearCookies },
                    },
                    [_vm._v(" Clear browsing data ")]
                  )
                : _vm._e(),
              _c(
                "RouterLink",
                { attrs: { to: _vm.SHARED_URLS.dashboard } },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "ml-3",
                      attrs: { face: _vm.FACES.fill, narrow: "" },
                    },
                    [_vm._v(" Go to dashboard ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }