var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingAlerts",
      attrs: { "data-test-id": "booking_alerts-widget" },
    },
    [
      _c(
        "h5",
        { staticClass: "emobg-label-3 emobg-font-weight-semibold mb-2" },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.bookingAlertsStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("TableComponent", {
            attrs: {
              schema: _vm.WIDGET_SCHEMAS.notConfirmedBookings,
              data: _vm.bookingAlerts,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }