<script>
import get from 'lodash/get';
import moment from 'moment-timezone';
import { mapActions, mapState } from 'vuex';
import { MuiDatePicker } from '@emobg/vue-base';
import { DASHBOARD_SCOPES } from '@domains/Dashboard/store/DashboardModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DATE_FORMAT, DATE_FORMAT_KEYS } from '@emobg/web-utils';
import { TableComponent } from '@/components';
import { WIDGET_SCHEMAS } from '../../const/widgets-config.const';
import { alertOcurrencesText } from '../../const/widgets.utils';

export default {
  name: 'BookingInvoicesKmsMismatch',
  components: {
    TableComponent,
    MuiDatePicker,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filters: {
        from: moment().subtract(2, 'months'),
        to: moment(),
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.dashboard.dashboard, {
      invoicesKmsMismatch: state => state[DASHBOARD_SCOPES.invoicesKmsMismatch].data || [],
      invoicesKmsMismatchStatus: state => state[DASHBOARD_SCOPES.invoicesKmsMismatch].STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: userState => get(userState, 'operators.active.id'),
    }),
    alertOcurrences() {
      return alertOcurrencesText(get(this, 'invoicesKmsMismatch.ocurrences'));
    },
  },
  watch: {
    activeOperatorId() {
      this.getWidgetData();
    },
    filters: {
      deep: true,
      handler() {
        this.getWidgetData();
      },
    },
  },
  async created() {
    this.WIDGET_SCHEMAS = WIDGET_SCHEMAS;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_FORMAT = DATE_FORMAT;
    await this.getWidgetData();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.dashboard.dashboard, ['getBookingInvoiceKmsMismatch']),
    async getWidgetData() {
      await this.getBookingInvoiceKmsMismatch({
        operatorId: this.activeOperatorId,
        data: {
          from: this.filters.from.format(DATE_FORMAT.date),
          to: this.filters.to.format(DATE_FORMAT.date),
        },
      });
    },
  },
};
</script>

<template>
  <div
    class="BookingInvoicesKmsMismatch"
    data-test-id="booking_mismatch-widget"
  >
    <h5 class="emobg-label-3 emobg-font-weight-semibold mb-2">
      {{ title }} ({{ alertOcurrences }})
    </h5>

    <p class="emobg-font-weight-semibold mb-2">
      Bookings finished between {{ moment(invoicesKmsMismatch.from).format(DATE_FORMAT.dob) }} and {{ moment(invoicesKmsMismatch.to).format(DATE_FORMAT.dob) }}
    </p>
    <div class="row mb-4">
      <MuiDatePicker
        v-model="filters.from"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        skip-time-step
        data-test-id="from-select"
        label="From"
        name="from"
        class="col-md-6"
      />

      <MuiDatePicker
        v-model="filters.to"
        :date-format-key="DATE_FORMAT_KEYS.dob"
        :valid-date-range="{
          start: filters.from,
          end: moment().add(50, 'years'),
        }"
        skip-time-step
        data-test-id="to-select"
        label="To"
        name="to"
        class="col-md-6"
      />
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="invoicesKmsMismatchStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <TableComponent
        :schema="WIDGET_SCHEMAS.invoicesKmsMismatch"
        :data="invoicesKmsMismatch.alerts"
      />
    </div>
  </div>
</template>
