var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "FinalOpenInvoices",
      attrs: { "data-test-id": "final_open_invoices-widget" },
    },
    [
      _c(
        "h5",
        { staticClass: "emobg-label-3 emobg-font-weight-semibold mb-2" },
        [
          _vm._v(
            " " + _vm._s(_vm.title) + " (" + _vm._s(_vm.alertOcurrences) + ") "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.finalAndOpenedInvoicesStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("TableComponent", {
            attrs: {
              schema: _vm.WIDGET_SCHEMAS.finalAndOpenedInvoices,
              data: _vm.finalAndOpenedInvoices.alerts,
              "data-test-id": "table",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }