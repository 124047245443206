var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingInvoicesKmsMismatch",
      attrs: { "data-test-id": "booking_mismatch-widget" },
    },
    [
      _c(
        "h5",
        { staticClass: "emobg-label-3 emobg-font-weight-semibold mb-2" },
        [
          _vm._v(
            " " + _vm._s(_vm.title) + " (" + _vm._s(_vm.alertOcurrences) + ") "
          ),
        ]
      ),
      _c("p", { staticClass: "emobg-font-weight-semibold mb-2" }, [
        _vm._v(
          " Bookings finished between " +
            _vm._s(
              _vm
                .moment(_vm.invoicesKmsMismatch.from)
                .format(_vm.DATE_FORMAT.dob)
            ) +
            " and " +
            _vm._s(
              _vm.moment(_vm.invoicesKmsMismatch.to).format(_vm.DATE_FORMAT.dob)
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "row mb-4" },
        [
          _c("MuiDatePicker", {
            staticClass: "col-md-6",
            attrs: {
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              "skip-time-step": "",
              "data-test-id": "from-select",
              label: "From",
              name: "from",
            },
            model: {
              value: _vm.filters.from,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "from", $$v)
              },
              expression: "filters.from",
            },
          }),
          _c("MuiDatePicker", {
            staticClass: "col-md-6",
            attrs: {
              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
              "valid-date-range": {
                start: _vm.filters.from,
                end: _vm.moment().add(50, "years"),
              },
              "skip-time-step": "",
              "data-test-id": "to-select",
              label: "To",
              name: "to",
            },
            model: {
              value: _vm.filters.to,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "to", $$v)
              },
              expression: "filters.to",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.invoicesKmsMismatchStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("TableComponent", {
            attrs: {
              schema: _vm.WIDGET_SCHEMAS.invoicesKmsMismatch,
              data: _vm.invoicesKmsMismatch.alerts,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }